import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import {
   Switch,
   Route,
} from "react-router-dom";
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/database'
import 'firebase/compat/analytics'
import Home from './Home'
import Login from './Login'
import Landing from './Landing'
import firestar from 'firestar'

const firebaseConfig = {
   apiKey: "AIzaSyCOPg09ZqJayfSIVdiyn6JFUQEF5IPv2T4",
   authDomain: "continuity-gifting-app.firebaseapp.com",
   databaseURL: "https://continuity-gifting-app-default-rtdb.firebaseio.com",
   projectId: "continuity-gifting-app",
   storageBucket: "continuity-gifting-app.appspot.com",
   messagingSenderId: "814625968432",
   appId: "1:814625968432:web:6ec7bad94761bcf99eb8f5",
   measurementId: "G-76CXQ7JZXF"
};

const env = process.env.REACT_APP_ENV

if (firebase.apps.length === 0) {
   const app = firebase.initializeApp(firebaseConfig)
   firebase.analytics()
   firestar.init(
      'admin',
      'browser',
      () => firebase.firestore,
      () => firebase.auth,
      () => firebase.storage,
      () => firebase.database,
      env === 'emulator' ? {
         app: app as any,
         host: 'localhost',
         port: {
            auth: 9099,
            database: 9000,
            firestore: 8080,
            storage: 9199
         }
      } : undefined
   )
}

export default function App() {

   const [userId, setUserId] = useState<string>()
   const [showLoader, setShowLoader] = useState(true)

   useEffect(() => {
      firebase.auth().onAuthStateChanged(onAuthStateChanged)
   }, [])

   if (showLoader == true) {
      return (
         <View>
            <Text>Loading...</Text>
         </View>
      )
   }

   return (
      <View>
         {userId ? (
            <Switch>
               <Route path='/' component={Home} />
            </Switch>
         ) : (
            <Switch>
               <Route exact path='/' component={Landing} />
               <Route path='/login' component={Login} />
            </Switch>
         )}
      </View>
   )

   async function onAuthStateChanged(user: firebase.User | null) {
      if(user){
         const token = await user.getIdTokenResult(true)
         const roles = token.claims?.roles ?? {}
         if(roles['account'] == null){
            firebase.auth().signOut()
            alert('You do not have access to this website. Please check with the account owner.')
         }else{
            setUserId(firebase.auth().currentUser?.uid)
         }
      }
      setShowLoader(false)
   }
}
