import React, { useState } from 'react'
import { View, Text, Alert } from 'react-native'
import Button from './components/Button'
import firebase from 'firebase/compat/app'

export default function Login() {

   const [showLoader, setShowLoader] = useState(false)

   if (showLoader === true) {
      return (
         <Text>Loading...</Text>
      )
   }

   return (
      <View style={{
         padding: 10,
		 alignSelf: 'center',
		 flex: 1,
		 justifyContent: 'center',
		 alignItems: 'center',
		 minHeight: '100vh'
      }}>
         <Button
            onPress={onPressLogin}
            title='Login with Google' />
      </View>
   )

   async function onPressLogin() {

      const provider = new firebase.auth.GoogleAuthProvider()
      setShowLoader(true)
      provider.setCustomParameters({ prompt: 'select_account' });
      try {
         const popUpResponse = await firebase.auth().signInWithPopup(provider)
         if (popUpResponse.credential != null) {
            await firebase.auth().signInWithCredential(popUpResponse.credential)
         }
      } catch (error) {
         console.log('Error SignIn with Pop Up', error)
         Alert.alert('Error Signing In', String(error))
      }
      setShowLoader(false)
   }

}
