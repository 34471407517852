import React from 'react'
import { View, Text } from 'react-native'
import {
   Link
} from 'react-router-dom'

export default function Landing() {
   return (
      <View style={{
         padding: 10,
		 justifyContent: 'center',
		 alignItems: 'center'
      }}>
         <h1>InfiGifts</h1>
		 <br/>
         <Link to='/login'>Login</Link>
      </View>
   )
}
