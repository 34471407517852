import React, { useState } from 'react'
import { View } from 'react-native'
import Button from './components/Button'
import firebase from 'firebase/compat/app'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import CasinoIcon from '@mui/icons-material/CasinoOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Gamepad from '@mui/icons-material/Gamepad';
import {
   useHistory,
   Switch,
   Route
} from 'react-router-dom'
import Dashboard from './Dashboard';
import CasinoList from './CasinoList';
import Users from './Users'
import Orders from './Orders'
import Playground from './Playground';
import Gaming from './Gaming';

function MenuItems() {
   return [
      {
         id: '/',
         title: 'Dashboard',
         renderIcon: () => <DashboardIcon />,
         component: Dashboard,
      },
      {
         id: '/casinos',
         title: 'Casinos',
         renderIcon: () => <CasinoIcon />,
         component: CasinoList,
      },
      {
         id: '/users',
         title: 'Users',
         renderIcon: () => <PersonIcon />,
         component: Users,
      },
      {
         id: '/orders/:id',
         title: 'Orders',
         renderIcon: () => <LocalShipping />,
         component: Orders,
         hidden: true
      },
      {
         id: '/orders',
         title: 'Orders',
         renderIcon: () => <LocalShipping />,
         component: Orders,
      },
      {
         id: '/playground',
         title: 'Playground',
         renderIcon: () => <Gamepad />,
         component: Playground,
         exact: false
      },
	  {
		id: '/gaming',
		title: 'Gaming',
		renderIcon: () => <Gamepad />,
		component: Gaming,
		exact: false
	 }
   ]
}

export default function Home() {

   const [showMenu, setShowMenu] = useState(false)
   const history = useHistory()

   return (
      <View>
         <AppBar position="static">
            <Toolbar>
               <IconButton
                  onClick={() => setShowMenu(!showMenu)}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}>
                  <MenuIcon />
               </IconButton>
               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  InfiGifts Admin
               </Typography>
               <Button
                  type='text'
                  titleStyle={{
                     fontSize: 13,
                     fontWeight: 'bold'
                  }}
                  onPress={onPressLogout}
                  title='LOGOUT' />
            </Toolbar>
         </AppBar>
         <Drawer
            anchor={'left'}
            open={showMenu}
            onClose={onCloseMenu}>
            <List>
               {MenuItems().filter(({ hidden }) => !(hidden ?? false)).map(({ title, id, renderIcon }, index) => (
                  <div>
                     <ListItem onClick={() => onPressMenu(id)} style={{
                        paddingRight: 50,
                        paddingLeft: 30,
                        paddingTop: 10,
                        paddingBottom: 10,
                     }} button key={id}>
                        <ListItemIcon>
                           {renderIcon()}
                        </ListItemIcon>
                        <ListItemText primary={title} />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
            </List>
         </Drawer>
         <Switch>
            {MenuItems().map(({ id, component, exact }) => (
               <Route exact={exact ?? true} path={id} component={component} />
            ))}
         </Switch>
      </View>
   )

   function onPressMenu(id: string) {
      history.push(id)
      setShowMenu(false)
   }

   function onCloseMenu() {
      setShowMenu(false)
   }

   function onPressLogout() {
      firebase.auth().signOut()
   }
}
